<template>
  <CRow>
    <CCol col="12" lg="8">
      <CCard style="max-width: 40rem;">
        <CCardHeader>
          Edit Customer
        </CCardHeader>
        <CCardBody>

            <CCardBody class="p-4"
            >
              <CForm>                
                <CAlert
                  :show.sync="credentialsCreated"
                  closeButton
                  color="success"
                >
                  New Credentials created!
                </CAlert>  
                <CInput
                  label="Name"
                  placeholder="Customer Name"
                  :key="customer.customer_id"
                  :value.sync="customer.customer_name"
                  valid-feedback="Customer name is valid."
                  invalid-feedback="Minimum length is 4 and only characters and spaces."
                  :is-valid="!$v.customer.customer_name.$invalid"
                  @input="inputChanged()" 
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput
                  label="Email"
                  placeholder="Customer Email"
                  v-model="customer.customer_email"
                  description="The Email can't be changed."
                  :disabled=true
                >
                  <template #prepend-content><CIcon name="cil-envelope-closed"/></template>
                </CInput>
                <CTextarea
                  label="Description"
                  v-model="customer.customer_desc"
                  :value.sync="customer.customer_desc"       
                  :is-valid="!$v.customer.customer_desc.$invalid"  
                  @input="inputChanged()" 
                  />                
                
                <div class="mb-5">
                  <CButton @click="getCredentialsModal = true" color="success"  variant="outline" class="float-left col-sm-5 mr-1"  >Retrieve Credentials</CButton>
                  <CButton @click="createCredentialsModal = true" color="warning"  variant="outline"  class="float-right col-sm-5 mr-1">Create new Credentials</CButton>
                </div>
                
              </CForm>
            
              <div class="pt-4">
                
                  <CButton
                  @click="cardCollapse = !cardCollapse"
                  tag="button"
                  color="link" 
                  block
                  class="text-left shadow-none card-header"
                > 
                
                <h5> 
                  <CIcon name="cil-caret-right" />  
                  Edit History 
                </h5>
                </CButton>                
                 <CCollapse :show="cardCollapse">
                <CDataTable
                  hover
                  striped
                  :items="customer.customer_editor"
                  :fields="fields"
                  :items-per-page="20"                
                  :pagination="{ doubleArrows: false, align: 'center'}"
                  
                >
              <td slot="customer_author_date" slot-scope="{item}">
                 {{ convertDate(item.customer_author_date) }}
              </td>   
                </CDataTable>
                </CCollapse>
                </div>
              
            </CCardBody>          
        </CCardBody>
        <CCardFooter>
          <CButton color="light" class="float-left col-sm-2" @click="goBack">Back</CButton>
          <CButton v-on:click="saveCustomer();" color="success" class="float-right col-sm-4"  :disabled="$v.$invalid || saveDisabled">Save Item</CButton>
        </CCardFooter>
      </CCard>
    </CCol>
    <CModal
      title="Customer Credentials"
      color="success"
      :show.sync="getCredentialsModal"
    >
      <CInput
        label="Username"
        key="customer_credentials.username"
        :value.sync="customerCredentials.username"
        disabled
      >
        <template #prepend-content><CIcon name="cil-user"/></template>
      </CInput>
      <CInput
        label="Password"
        key="customer_credentials.password"
        :value.sync="customerCredentials.password"
        disabled
      >
      
        <template #prepend-content><CIcon name="cil-lock-locked"/></template>
      </CInput>
      <div slot="footer" class="w-100">
          <CButton @click="getCredentialsModal = false"  color="light" class="ml-1 mr-1 float-right" >Close</CButton>  
      </div>
    </CModal>
    <CModal
      title="Create new Credentials"
      color="warning"
      :show.sync="createCredentialsModal"
    >
      <p>Do you really want to create new credentials for the customer <strong> {{ customer.customer_name }}</strong>?</p>
      <p>This <strong>can't</strong> be undone!</p>
        
      <div slot="footer" class="w-100">
          <CButton v-on:click="createCustomerCredentials();" color="warning" class="ml-1 mr-1 float-right" >Create</CButton>  
          <CButton @click="createCredentialsModal = false"  color="light" class="ml-1 mr-1 float-right" >Close</CButton>  
      </div>
    </CModal>           
  </CRow>
  
</template>


<script>
import CustomerService from '@/services/customer.services';
import moment from 'moment'
import { required, email, minLength, maxLength, helpers } from 'vuelidate/lib/validators';
const alphaNumAndDotValidator = helpers.regex('alphaNumAndDot', /^([a-zA-Z0-9-_\\.&!#=()\\s])*$/i);
const customerDescValidator = helpers.regex('alphaNumAndDot', /^([a-zA-Z0-9- _\,.&!#=\\\/()\s]){0,}$/i);


export default {
  data() {
    return {
      customer: {},
      saveDisabled: true,
      cardCollapse: false,
      fields: [
        { key: 'customer_author_name', label: 'User' },
        { key: 'customer_author_date', label: 'Change Date' }
      ],
      customerCredentials: {},
      getCredentialsModal: false,
      createCredentialsModal: false,
      credentialsCreated: false
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations: {
    customer: {
      customer_name: {
        minLength: minLength(4),
        maxLength: maxLength(32),
        required,
        alphaNumAndDotValidator,
        $autoDirty: true
      },
      customer_email: {
        required,
        email
      },
      customer_desc: {
        minLength: minLength(0),
        maxLength: maxLength(2048),        
        customerDescValidator,
        $autoDirty: true
      }
    }
  },
  methods: {
    convertDate(timestamp) {
      var date = new Date(parseInt(timestamp))      
      return moment(date).format('DD.MM.YYYY - H:mm:ss ')
    },
    getUserAttributes(cb) {
      this.$cognitoAuth.getUserAttributes(function(result, success) {
        if (success) {
          var userAttributes = {}
          for (var key in result) {
            userAttributes[result[key].getName()] = result[key].getValue()
          }         
          return cb(userAttributes, true)
        }
        return cb({}, false)
      });

    },    
    goBack() {
      this.$router.push({path: '/customers'})
    },
    inputChanged() {
      if(this.saveDisabled) {
        this.saveDisabled = false;
      }
    },
    createCustomerCredentials() {
      this.createCredentialsModal = false;
 
      CustomerService.createCustomerCredentials(this.$route.params.id).then(
        response => { 
          this.customerCredentials = response;
          this.credentialsCreated = true;
        },
        error => {   
          console.log(error)   
        }      
      )

    },
    async saveCustomer() {
      CustomerService.saveCustomer(this.customer.customer_id, this.customer.customer_name, this.customer.customer_desc, this.$cognitoAuth.getCurrentUser().username).then(
        response => { 
          this.saveDisabled = true;
        },
        error => {   
          console.log(error)   
        }
      )
    }           
     
  },
  created() { },
  mounted() {
    CustomerService.getCustomer(this.$route.params.id).then(
      response => { 
        this.customer = response;
      },
      error => {   
        console.log(error)   
      }      
    ),
    CustomerService.getCustomerCredentials(this.$route.params.id).then(
      response => { 
        this.customerCredentials = response;
      },
      error => {   
        console.log(error)   
      }      
    )
  },

};

</script>
